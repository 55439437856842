@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=gilroy');
@import url('https://fonts.googleapis.com/css?family=Playfair Display');
@import url('https://fonts.googleapis.com/css?family=Product Sans');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');




html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
  height: 100%;
  background-color: #FAFAFA;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: #ECECEC !important;
}

/************ Home Page ***************/

.mainbg {
  background-image: url('img/spania-banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: black;
  position: relative;
}

.bannertxt {
  position: absolute;
  left: 54%;
  top: 25%;
}

.mainbg p.spania {
  color: #FFF;
  font-size: 48px;
  font-family: 'Gilroy-Black';
  font-weight: bold;
  line-height: 125%;
  letter-spacing: 10.08px;
  text-transform: uppercase;
  padding-bottom: 40px;
}

.mainbg p.glow {
  color: #FFF;
  font-size: 68px;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  padding-bottom: 40px;
}

/*.mainbg a.bookapp{
  font-family: 'Product Sans';
  background: #FF4F9D;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  border-radius: 3px;
  padding: 10px 25px 10px 25px;
}*/
.select {
  /* margin: 4px; */
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #EBEBEB;
  text-align: center;
}

.select:hover {
  background-color: #30BF58;
  border-color: #30BF58;
}

.select:hover label .label:nth-of-type(2) {
  position: relative;
  top: 0;
  display: block;
  color: #fff;
}

.select:hover label .label {
  display: none;
}

.select label {
  width: 100%;
  line-height: 3.0em;
}

.select label .label {
  text-align: center;
  color: #000;
  display: block;
}

.select label .label:nth-of-type(2) {
  display: none;
  font-size: 20px;
}

.select label input {
  position: absolute;
  display: none;
  color: #fff !important;
}

/* selects all of the text within the input element and changes the color of the text */
.select label input+span {
  color: #fff;
}

.select label .checkmark {
  display: none;
}


/* This will declare how a selected input will look giving generic properties */
.select input:checked+.lable {
  color: #ffffff;
  display: none;
}

.select input:checked~.label:nth-of-type(1) {
  display: none;
}

.select input:checked~.label:nth-of-type(2) {
  display: block;
  color: #fff;
}

.select:has(input:checked) {
  background-color: #30BF58;
  border-color: #30BF58;
}

.mainbg a {
  font-family: 'Product Sans';
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  top: 105%;
  left: -51%;
  right: 0;
  bottom: 0;
  margin: auto;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #EE3769;
  border-radius: 8px;
  height: 60px;
  width: 250px;
  padding: 0;
  outline: none;
  overflow: hidden;
  color: #EE3769;
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
  line-height: 250%;
  z-index: 0;
}

.mainbg a::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  border-radius: 50%;
  display: block;
  width: 22em;
  height: 20em;
  line-height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.mainbg a:hover {
  color: #fff;
}

.mainbg a:hover::before {
  box-shadow: inset 0 0 0 10em #ee3769;
}

/********Inner Page*************/

#register a {
  color: #000;
}


#register .spaniain,
#services .spaniain {
  color: #000;
  font-size: 32px;
  font-family: 'Gilroy-Black';
  font-weight: bold;
  line-height: 125%;
  letter-spacing: 6.72px;
}

.banner {
  background: url(img/banner.jpg);
  background-size: cover;
  color: #fff;
  min-height: 100px;
  position: relative;
}

p.heading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #FFF;
  font-size: 32px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.7px;

}

.list-group,
.list-group-item {
  background-color: #FAFAFA;
  width: 100%;
}

.list-group a {
  color: #000;
  background-color: #FAFAFA;
  width: 100%;
  display: block;
}



.list-group a:hover {
  color: #000;

}

.list-group-item {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #FAFAFA;
}

.list-group-item:hover {
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
}



.listtxt h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-bottom: 1px solid #ccc;

}

.thirdcol {
  position: sticky;
  top: 2%;
}

.thirdcol h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}


.listtxt .selectbtn {
  width: 90px;
  height: 36px;
  border: 1px solid #868686;
  color: #868686;
  position: relative;
  margin-top: -6px;
}

.listtxt button.selectbtn:hover {
  background-image: url(img/tick.png);
  background-color: #30BF58;
  background-repeat: no-repeat;
  border: 1px solid #30BF58;
  background-position: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 100%;
  overflow: auto;
  position: sticky;
  top: 5%;
}

.listtxt hr {
  margin: 0;
}

.thirdcol hr {
  margin-top: 15px;
}

.dateandtime .nav {
  display: inherit;
  text-align: center;
}

.avialability {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.avialability .nav {

  justify-content: center;
}

.avialability button.timings {
  height: 36px;
  border: 1px solid #30BF58;
  color: #30BF58;
  border-radius: 5px;
  margin: 0px 0px 10px 0px;
}

.avialability .nav-vertical {
  list-style-type: none;
  text-align: center;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.avialability button.seltimings {
  background-color: #30bf58;
  border: 1px solid #30bf58;
  border-radius: 5px;
  color: #fff;
  height: 36px;
  margin: 0 0 10px;
}

#contentarea2 .avialability .morningbtn {
  width: 120px;
  background-color: #dadada;
  border-radius: 5px;
  margin: 40px 0px 30px 0px;
}

#contentarea2 .dateandtime .morningbtn {
  width: 120px;
  background-color: #dadada;
  border-radius: 5px;
  margin: 40px 0px 30px 0px;
}

.dateandtime button.timings {
  height: 36px;
  border: 1px solid #30BF58;
  color: #30BF58;
  border-radius: 5px;
  margin: 0px 0px 10px 0px;
}

.dateandtime button.distimings {
  height: 36px;
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  margin: 0px 0px 10px 0px;
}

.dateandtime button.seltimings {
  height: 36px;
  background-color: #30BF58;
  border: 1px solid #30BF58;
  color: #fff;
  border-radius: 5px;
  margin: 0px 0px 10px 0px;
}

.thirdcol .blubox {
  background: #DFFAFF;
  border-radius: 5px;
  color: #45433F;
  margin: 0px 10px 0px 10px;
  padding: 18px 12px;
}


#footer {
  background-color: #fff;
  border-top: 1px solid #ccc;
}

#footer a {
  color: #000;
}

#footer a.desined span img {
  width: 17px;
}

.thirdcol .addbtn {
  border: 1px solid #868686;
  color: #fff !important;
  background-color: #000;
  font-size: 14px;
  font-family: 'Poppins';
  width: 100%;
  margin: auto;
  text-align: center;
}

/* Login */


.loginbox {
  max-width: 700px;
  margin: 40px auto 80px auto;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.spaniabox {
  max-width: 500px;
  margin: 40px auto 50px auto;
  padding: 30px 45px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

#services a {
  color: #000;
}

#services a.blutxt {
  color: #006AFF;
}

#services a:hover {
  color: #30BF58;
}

.bordblk {
  border-top: 5px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

#services .btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: #30BF58;
  border-color: #30BF58;
}

.loginbox label {
  display: block;
  padding-bottom: 5px;
}

.social-icons img {
  width: 40px;
  height: 40px;
  margin: 0px 5px 50px 0px;
}

.btn-dark-outline {
  color: #212529;
  background-color: #fff;
  border-color: #212529;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 0 !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
} */

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}


/* PROFILE DASHBOARD CSS */
.item-box {
  padding: 60px;
  text-align: center;
  font-size: 20px;
  /* line-height: 36px; */
}

.item-box p {
  height: 50px;
}



.font90 {
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
}

.list-group-item>.sidebar-content {
  height: 0;
  overflow: hidden;
}

.list-group-item.open>.sidebar-content {
  height: auto;
}

/* #contentarea .card {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
} */



@media (max-width: 575.98px) {

  .react-calendar__tile:disabled,
  .react-calendar__month-view__weekdays {
    display: none !important;
  }


  #contentarea,
  #contentarea2 {
    min-height: 75vh;
  }

  .navbar-light .navbar-toggler {
    border: 0;
  }

  nav button:focus:not(:focus-visible) {
    box-shadow: none;
  }

  p.heading {
    font-size: 24px;
  }

  #footer .nav {
    justify-content: center;
  }

  .blubox {
    background-color: #DFFAFF;
  }

  .mobileTabs .nav-link.active {
    background-color: #000 !important;
  }

  .mobileTabs .nav-link {
    color: #757575;
  }

  .avialability {
    box-shadow: none;
    border: 0
  }

  .banner {
    min-height: 60px;
  }

  #footer a,
  #footer p {
    color: #000;
    font-size: 11px;
  }

  .loginbox {
    margin-top: 0;
    /* padding-left: 20px;
    padding-right: 20px; */
  }

  #contentarea2 {
    background-color: #fff;
  }

  .mobileCheckout {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .navbar-light .expanded .navbar-toggler-icon {}

  .collapse1.is-open {
    opacity: 1 !important;
    visibility: visible !important;
  }

  #navbarSupportedContent1 {
    position: absolute;
    background-color: #000;
    color: #fff;
    left: 0;
    right: 0;
    /* bottom: 0; */
    height: 100vh;
    z-index: 9;
    padding: 15px;
    z-index: 99999;
    transition: opacity 0.3s ease-in-out, display 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

  }

  #contentarea .card {
    box-shadow: none !important;
  }

  .tab-content {
    margin-bottom: 150px;
  }

  #contentarea4 {
    background-color: #fff;
    min-height: 75vh;
  }

  #contentarea4 .card {
    box-shadow: none !important;
    border: 0;
  }

  #contentarea4 h3 {
    font-size: 18px;
  }



  #navbarSupportedContent1 ul li>a {
    color: #fff;
    font-size: 20px;
  }

  #navbarSupportedContent1 ul li {
    margin-bottom: 15px;
  }

  .submenu-items {
    list-style-type: none;
    position: relative;
  }

  .submenu-items>li::before {
    position: absolute;
    content: '-';
    color: #fff;
    left: 10px;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url('img/toggle.png') !important;
    width: 1.2rem;
  }

  .navbar-light .expanded .navbar-toggler-icon {
    background-image: url('img/toggle-close.png') !important;
    width: 1.2rem;
  }

  #contentarea,
  #contentarea2 {
    min-height: 75vh;
  }

  .navbar-light .navbar-toggler {
    border: 0;
  }

  nav button:focus:not(:focus-visible) {
    box-shadow: none;
  }

  p.heading {
    font-size: 24px;
  }

  #footer .nav {
    justify-content: center;
  }

  .blubox {
    background-color: #DFFAFF;
  }

  .mobileTabs .nav-link.active {
    background-color: #000 !important;
  }

  .mobileTabs .nav-link {
    color: #757575;
  }

  .avialability {
    box-shadow: none;
    border: 0
  }

  .banner {
    min-height: 60px;
  }

  #footer a,
  #footer p {
    color: #000;
    font-size: 11px;
  }

  .loginbox {
    margin-top: 0;
    /* padding-left: 20px;
    padding-right: 20px; */
  }

  #contentarea2 {
    background-color: #fff;
  }
}